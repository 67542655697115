<template>
  <div class="bg-white h-auto px-5 rounded-lg">
    <div class="flex flex-col lg:flex-row md:items-center gap-2">
      <div class="flex items-center w-full justify-between">
        <Heading heading="Campus Attendance" heading-size="text-[18px]" />
        <div
          class="xl:h-11 md:h-10 h-9 flex items-center cursor-pointer justify-center gap-2 bg-white border rounded-lg px-2"
        >
          <icon icon="filter" height="20" width="20" color="primary-gray-700" />
          <span class="font-inter font-semibold text-primary-gray-700 text-sm hidden sm:block">
            Filters
          </span>
        </div>
      </div>
      <div class="w-full lg:w-fit flex flex-col gap-2 sm:flex-row items-end justify-end">
        <UiFilterBox
          :options="campuses"
          :select-index="selectedindex"
          :switch-box="true"
          @optionChanged="handleOptionChanged"
        />
        <CalendarActionsWrapper
          :is-future-date-disabled="true"
          :calendar-date="selectedDate"
          custom-class="right-0 before:right-12"
          :filter-view="filters.filterBy"
          @selectDate="setDate"
        ></CalendarActionsWrapper>
      </div>
    </div>
    <div class="overflow-auto">
      <Loader v-if="isLoading" class="mt-20 mb-40" :content="true" />
      <GraphView
        v-else
        :chart-data="graphData"
        tooltip="campusAttendanceDashboard"
        class="min-w-7"
      />
    </div>
    <div class="pb-6 flex mt-2">
      <UiStatsCountDisplay class="flex-1" title="Total Students:" :value="studentsStrength" />
    </div>
  </div>
</template>

<script setup>
import { ref, computed, onMounted, watch, reactive } from 'vue'
import UiStatsCountDisplay from '@/src/components/UiElements/UiStatsCountDisplay.vue'
import UiFilterBox from '@/src/components/UiElements/UiFilterBox.vue'
import CalendarActionsWrapper from '@components/Calendar/CalendarActionsWrapper.vue'
import Heading from '@/src/components/Heading.vue'
import GraphView from '@src/router/views/graph/graphView.vue'
import icon from '@src/components/icons/icon.vue'
import Loader from '@src/components/BaseComponent/Loader.vue'
import { useStore } from 'vuex'
import { arrayRange } from '@utils/generalUtil'
import { getDaysInMonth } from '@src/utils/moment.util.js'
import isArray from 'lodash/isArray'

// State management
const store = useStore()

// Refs
const filters = reactive({ filterBy: 'Month' })
const isLoading = ref(false)
const selectedCampus_id = ref('')
const selectedDate = ref(new Date())
const checkedIn = ref([])
const checkedOut = ref([])
const studentsStrength = ref('')
const daysInMonth = ref(30)
const selectedindex = ref(0)

// Computed properties
const currentCampusScope = computed(() => store.state.layout.currentCampusScope)
const currentClassScope = computed(() => store.state.layout.currentClassScope)
const currentSectionScope = computed(() => store.state.layout.currentSectionScope)
const campuses = computed(() =>
  currentCampusScope.value ? currentCampusScope.value : store.state.layout.campuses,
)

// Actions
const getLastMarkedCampusAttendance = () =>
  store.dispatch('instituteCount/getLastMarkedCampusAttendance')
const getCampusAttendanceStats = (payload) =>
  store.dispatch('instituteCount/getCampusAttendanceStats', payload)

// Methods
const campusAttendanceStats = async () => {
  if (selectedCampus_id.value) {
    const selectedMonth = selectedDate.value.getMonth() + 1
    const selectedYear = selectedDate.value.getFullYear()

    isLoading.value = true

    const payload = {
      month: selectedMonth,
      year: selectedYear,
    }
    if (!currentCampusScope.value) {
      payload.$where = { campus_id: { $equal: selectedCampus_id.value } }
    }
    try {
      const response = await getCampusAttendanceStats(payload)
      checkedIn.value = response.stats_by_month.map((entry) => entry.total_checked_in)
      checkedOut.value = response.stats_by_month.map((entry) => entry.total_checked_out)

      studentsStrength.value = response.students_strength
    } finally {
      isLoading.value = false
    }
  }
}

const selectLastAttendanceCampus = async () => {
  isLoading.value = true
  try {
    const response = await getLastMarkedCampusAttendance()
    if (response) {
      if (isArray(response))
        selectedindex.value = campuses.value.findIndex((item) => item.id === response.id)
      selectedCampus_id.value = response.id
    } else {
      selectedindex.value = 0
      selectedCampus_id.value = campuses.value[0].id
    }
    await campusAttendanceStats()
  } finally {
    isLoading.value = false
  }
}

const setDate = (date) => {
  selectedDate.value = date
  campusAttendanceStats()
}

const handleOptionChanged = (optionId) => {
  selectedCampus_id.value = optionId
  campusAttendanceStats()
}

// Graph data setup
const graphData = reactive({
  date: { month: selectedDate.value.getMonth(), year: selectedDate.value.getFullYear() },
  type: 'bar',
  data: {
    labels: arrayRange(1, 30),
    datasets: [
      {
        label: 'Campus In',
        backgroundColor: '#7F56D9',
        data: checkedIn.value,
      },
      {
        label: 'Campus Out',
        backgroundColor: '#D6BBFB',
        data: checkedOut.value,
      },
    ],
  },
  options: {
    responsive: true,
    pointRadius: 4,
    responsive: true,
    maintainAspectRatio: false,
    aspectRatio: 100 / 80,
    borderRadius: { topLeft: 5, topRight: 5, bottomLeft: 0, bottomRight: 0 },
    borderSkipped: false,
    barPercentage: 0.6,
    categoryPercentage: 0.6,

    scales: {
      x: { grid: { display: false } },
      y: { ticks: { stepSize: 1 } },
    },

    interaction: {
      mode: 'index',
    },
    plugins: {
      tooltip: { backgroundColor: 'white', titleColor: 'black' },
      legend: {
        display: true,
        position: 'top',
        align: 'end',
        labels: {
          boxWidth: 8,
          boxHeight: 8,
          usePointStyle: true,
          padding: 13,
          font: { size: 14 },
          textAlign: 'center',
        },
      },
    },
    pointRadius: 4,
    maxBarThickness: 8,
    maintainAspectRatio: false,
    aspectRatio: 100 / 80,
    borderRadius: { topLeft: 5, topRight: 5, bottomLeft: 0, bottomRight: 0 },
    borderSkipped: false,
  },
})

// Watchers
watch(
  [checkedIn, checkedOut],
  () => {
    graphData.data.datasets[0].data = checkedIn.value
    graphData.data.datasets[1].data = checkedOut.value
    daysInMonth.value = getDaysInMonth(selectedDate.value)
    graphData.data.labels = arrayRange(1, daysInMonth.value)
    graphData.date = {
      month: selectedDate.value.getMonth(),
      year: selectedDate.value.getFullYear(),
    }
  },
  { deep: true },
)
watch([currentCampusScope, currentClassScope, currentSectionScope], () => {
  campusAttendanceStats()
})
onMounted(() => {
  selectLastAttendanceCampus()
})
</script>
